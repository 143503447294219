@font-face {
  font-family: 'Poppins-Black';
  src: url('../assets/fonts/Black/Poppins-Black.woff2') format('woff2'),
  url('../assets/fonts/Black/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../assets/fonts/Bold/Poppins-Bold.woff2') format('woff2'),
  url('../assets/fonts/Bold/Poppins-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../assets/fonts/Regular/Poppins-Regular.woff2') format('woff2'),
  url('../assets/fonts/Regular/Poppins-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

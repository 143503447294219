$purple: #7868e6;
$light-purple: #b8b5ff;
$very-light-purple: #edeef7;
$light-blue: #e4fbff;
$white: #ffffff;
$black: #000000;
$facebook-color: #3b5998;
$instagram-color: #8a3ab9;
$linkedin-color:  #0077b5;
$github-color: #24292E;
$green: #5cd4ca;

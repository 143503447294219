@media only screen and (max-width: 767px){
  h1{
    font-size: 40px !important;
  }
  h2{
    font-size: 32px !important;
    line-height: 50px !important;
  }
  .modal .modal-full-height{
    width: 100% !important;
  }
}

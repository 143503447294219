/* You can add global styles to this file, and also import other style files */
@import 'style/_fonts.scss';
@import 'style/variables';
@import 'style/media';

html{
  scroll-behavior: smooth;
}

body{
  font-family: Poppins-Regular;
}

ul{
  padding: 0;
  margin: 0;
  li{
    display: inline-block;
    font-family: Poppins-Regular;
  }
}

h1{
  font-family: Poppins-Black;
  color: $white;
  font-size: 55px;
}

h2{
  font-family: Poppins-Black;
  color: $purple;
  font-size: 40px;
  line-height: 65px;
}

h3{
  font-size: 24px;
  font-family: 'Poppins-Bold';
  line-height: 24px;
}

p{
  font-size: 18px;
  font-family: Poppins-Regular;
  line-height: 25px;
  color: $white;
}

.py-30{
  padding: 30px 0;
}

.py-80{
  padding: 80px 0;
}

.pb-30{
  padding-bottom: 30px;
}

.pb-70{
  padding-bottom: 70px;
}

.mt-25{
  margin-top: 25px;
}

.button{
  min-width: 250px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 50px;
  border: 2px solid $purple;
  border-radius: 0px;
  background: $white;
  font-family: Poppins-Bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: $purple;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover{
    background-color: $purple;
    color: $white;
  }
}

.image{
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.section-title{
  padding-bottom: 40px;
  position: relative;

  h2{
    position: relative;
    display: inline-block;

    &:before{
      content: "";
      position: absolute;
      width: calc(100% - 50%);
      height: 3px;
      bottom: -5px;
      left: 43%;
      transform: translateX(-50%);
      background: $light-purple;
      border-radius: 40px;
      z-index: 0;
    }

    &:after{
      content: "";
      position: absolute;
      width: calc(100% - 90%);
      height: 3px;
      border-radius: 40px;
      bottom: -5px;
      left: 76%;
      transform: translateX(-50%);
      background: $light-purple;
      z-index: 0;
    }
  }
}


body.modal-open{
  overflow-y: hidden !important;
}
